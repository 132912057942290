import { createStore } from 'vuex'

export default createStore({
  state: {
    eyeXPosition: 0 as number,
    eyeYPosition: 0 as number,
    isCameraRunning: false as boolean,
    lang: '' as string
  },
  mutations: {
    setEyeXPosition: function (context, newXPosition) { context.eyeXPosition = newXPosition },
    setEyeYPosition: function (context, newYPosition) { context.eyeYPosition = newYPosition },
    setIsCameraRunning: function (context, newIsCameraRunning) { context.isCameraRunning = newIsCameraRunning },
    setLang: function (context, newLang) { context.lang = newLang }
  },
  actions: {
  },
  modules: {
  }
})
