<template>
  <div class="relative">
    <!-- <div v-if="showCameraInfo" class="fixed top-10 right-0 text-xs p-2 bg-green-900 w-80 z-50 rounded-lg">
      <div class="close ml-auto cursor-pointer text-right text-transparent" @click="showCameraInfo = false">&#10006;</div>
      <div>
        If you want to have some effects based on your eyes position then please allow us to use your camera.
      </div>
    </div> -->
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
import localforage from 'localforage'

export default defineComponent({
  data: function () {
    return {
      showCameraInfo: true
    }
  },
  methods: {
    initWebgazer: function (eyeGazeData) {
      // if (eyeGazeData && eyeGazeData.length > 0) {
      window.webgazer
        .setGazeListener((data, elapsedTime) => {
          if (data == null) {
            return
          }
          this.showCameraInfo = false
          this.$store.commit('setIsCameraRunning', true)
          this.$store.commit('setEyeXPosition', data.x)
          this.$store.commit('setEyeYPosition', data.y)
          /* if (
            yprediction > 0.8 * window.visualViewport.height &&
            yprediction < window.visualViewport.height
          ) {
            window.scrollBy(0, 10)
          } else if (yprediction < 0.2 * window.visualViewport.height && yprediction > 0) {
            window.scrollBy(0, -10)
          } */
        })
        .begin()
      /* setTimeout(() => {
        localforage.getItem('webgazerGlobalData').then(data => {
          const maxSize = 20
          const hunderts = Math.ceil(data.length / maxSize)
          if (data.length > 0) {
            for (let i = 0; i <= hunderts; i++) {
              axios.post(
                'http://localhost:8081/api/addEyeGaze',
                { data: data.slice(i * maxSize, (i + 1) * maxSize) }
              )
            }
          }
        })
      }, 45000) */
      // }
    }
  },
  beforeMount () {
    // axios.get('/api/getEyeGaze').then(
    //   response => {
    //     response.data.result.forEach(eye => {
    //       const eyeLeftDataArray = Object.keys(eye.eyes.left.patch.data).map(key => eye.eyes.left.patch.data[key])
    //       const eyeRightDataArray = Object.keys(eye.eyes.right.patch.data).map(key => eye.eyes.right.patch.data[key])
    //       eye.eyes.left.patch.data = eyeLeftDataArray
    //       eye.eyes.right.patch.data = eyeRightDataArray
    //     })
    //     localforage.setItem('webgazerGlobalData', response.data.result)
    //       .then(() => {
    //         localforage.getItem('webgazerGlobalData')
    //         this.initWebgazer(response.data.result)
    //       })
    //       .catch(() => this.initWebgazer(response.data.result))
    //   }
    // )
  }
})
</script>

<style lang="scss">
  @import '@/styles/main.scss';
  .close {
    text-shadow: 0 0 0 white;
  }
</style>
