<template>
  <div class="pt-10 home">
    <div
      class="notSeeMeee flex items-center h-10 px-5 py-2.5 fixed top-0 w-full text-gray-400 bg-gray-800 z-20"
      :class="!isLookingRight && isLookingTop || !$store.state.isCameraRunning ? 'seeMeee' : ''"
    >
      <div
        class="languageSelector cursor-pointer bg-gray-800 border rounded border-gray-400 w-40 overflow-hidden"
        @click="showLocales = !showLocales"
        tabindex="0"
        @blur="showLocales = false"
        :class="showLocales ? 'absolute top-2.5' : 'relative'"
      >
        <div class="languageSelector-selected text-white px-4 py-0.5 hover:font-bold text-center">{{ langNativeName }}</div>
        <div v-if="showLocales" class="languageSelector-list z-50 bg-gray-800 w-full">
          <div
            class="languageSelector-item px-4 py-0.5 text-center hover:font-bold"
            :key="index"
            v-for="(lang, index) in langs.filter(lang => lang.nativeName !== $store.state.lang)"
            @click="changeLocale(lang.code)"
          >
            {{ lang.name }}
          </div>
        </div>
      </div>
      <router-link
        class="ml-auto mr-5 hover:font-bold hidden md:block"
        :class="activeLink === '#aboutUs' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#aboutUs' }"
      >{{$t('about.desktop')}}</router-link>
      <router-link
        class="ml-auto mr-5 hover:font-bold block md:hidden"
        :class="activeLink === '#aboutUs' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#aboutUs' }"
      >{{$t('about.mobile')}}</router-link>
      <router-link
        class="mr-5 hover:font-bold hidden md:block"
        :class="activeLink === '#ourProducts' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#ourProducts' }"
      >{{$t('products.desktop')}}</router-link>
      <router-link
        class="mr-5 hover:font-bold block md:hidden"
        :class="activeLink === '#ourProducts' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#ourProducts' }"
      >{{$t('products.mobile')}}</router-link>
      <router-link
        class="mr-5 hover:font-bold hidden md:block"
        :class="activeLink === '#ourTechnologies' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#ourTechnologies' }"
      >{{$t('technologies.desktop')}}</router-link>
      <router-link
        class="mr-5 hover:font-bold block md:hidden"
        :class="activeLink === '#ourTechnologies' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#ourTechnologies' }"
      >{{$t('technologies.mobile')}}</router-link>
      <router-link
        class="hover:font-bold"
        :class="activeLink === '#contact' ? 'text-gray-50': ''"
        :to="{ name: 'Home', hash: '#contact' }"
      >{{$t('contact.menuButton')}}</router-link>
    </div>
    <div id="aboutUs" ref="aboutUs" class="relative p-5 flex items-center bg-gray-700" :style="{ opacity: aboutUsOpacity }">
      <div class="homeHero filter blur-md absolute w-full h-full left-0 top-0 z-0"></div>
      <div class="relative z-10 w-full">
        <h1 class="text-3xl md:text-5xl font-bold mb-10">
          {{$t('hero.title')}}
        </h1>
        <div class="wrapper">
          <div class="text-gray-50 text-lg md:text-xl mb-4">{{$t('hero.text1')}}</div>
          <div class="text-gray-50 text-lg md:text-xl mb-16">{{$t('hero.text2')}}</div>
        </div>
        <button @click="$router.push({ hash: '#ourProducts' })" class="mx-auto h-12 flex items-center justify-items-center px-4 rounded-md bg-blue-400 text-gray-700 font-bold outline-none">
          {{$t('hero.button')}}
        </button>
      </div>
    </div>
    <div id="ourProducts" ref="ourProducts" class="relative p-5 pt-16 pb-20 bg-gray-700" :style="{ opacity: ourProductsOpacity }">
      <div class="productsSection">
        <h1 class="section text-5xl font-bold mb-20 text-center">
          {{$t('products.desktop')}}
        </h1>
        <div class="grid gap-20 grid-cols-1 md:grid-cols-2 justify-around">
          <div
            class="product"
            :class="isLookingRight || !$store.state.isCameraRunning ? 'seeMeee' : 'notSeeMeee'"
            @click="goTo('https://hospitoolity.com')"
          >
            <div>
              <img class="mb-10 block mx-auto h-24" src="@/assets/hospitoolityLogo.png" alt="">
              <div class="text-md">
                {{$t('hospitoolity')}}
              </div>
            </div>
          </div>
          <div
            class="product"
            :class="isLookingLeft || !$store.state.isCameraRunning ? 'seeMeee' : 'notSeeMeee'"
            @click="goTo('https://zajmesie.pl')"
          >
            <div>
              <img class="mb-10 block mx-auto h-14" src="@/assets/popilnuje.svg" alt="">
              <div class="text-md">
                {{$t('popilnuje')}}
              </div>
            </div>
          </div>
          <div
            class="product"
            :class="isLookingRight || !$store.state.isCameraRunning ? 'seeMeee' : 'notSeeMeee'"
            @click="goTo('https://commissionforge.com')"
          >
            <div class="bg-green-400 opacity-50 rounded p-2">
              <div class="text-2xl text-center mb-2 rounded">COMMING SOON</div>
              <img class="mb-2 block mx-auto h-24" src="@/assets/commissionLogo.png" alt="">
              <div class="text-md">
                {{$t('commission-forge')}}
              </div>
            </div>
          </div>
          <div
            class="product"
            :class="isLookingLeft || !$store.state.isCameraRunning ? 'seeMeee' : 'notSeeMeee'"
            @click="goTo('http://e-carpenter.pl')"
          >
            <div>
              <!-- <img class="mb-10 block mx-auto h-24" src="@/assets/e-carpenter.svg" alt=""> -->
              <div class="h-24 flex mb-10 items-center justify-center">
                <div class="bg-eCarpenterPrimary p-2 rounded h-full flex items-center justify-center">
                  <img class="h-full me-2.5" src="@/assets/e-carpenter.svg" alt="">
                  <div class="text-eCarpenterTextPrimary text-lg font-bold">E-Carpenter</div>
                </div>
              </div>
              <div class="text-md">
                {{$t('e-carpenter')}}
              </div>
            </div>
          </div>
          <router-link :to="{ name: 'Home', hash: '#contact' }">
            <div
              class="product"
              :class="isLookingRight || !$store.state.isCameraRunning ? 'seeMeee' : 'notSeeMeee'"
            >
              <div>
                <div class="h-24 w-24 flex mb-10 mx-auto items-center justify-center relative border-2 border-dashed rounded">
                  <div class="absolute h-20 w-2 rounded bg-gray-200"></div>
                  <div class="absolute h-2 w-20 rounded bg-gray-200"></div>
                </div>
                <div class="text-md">
                  {{$t('nextProduct')}}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div id="ourTechnologies" ref="ourTechnologies" class="relative p-5 pt-16 pb-20 bg-gray-700" :style="{ opacity: ourTechnologiesOpacity }">
      <div class="section">
        <div class="w-full h-full absolute top-0 left-0 z-10" :style="{ background: lightPosition }"></div>
        <h1 class="text-5xl font-bold mb-10">
          {{$t('technologies.desktop')}}
        </h1>
        <div class="flex flex-wrap gap-14 grid-cols-3 auto-cols-fr justify-around">
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/HTML5Logo.svg" alt="">
              <div class="text-lg text-center">HTML</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/CSSLogo.svg" alt="">
              <div class="text-lg text-center">CSS</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/SCSSLogo.png" alt="">
              <div class="text-lg text-center">SCSS</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/vueLogo.png" alt="">
              <div class="text-lg text-center">Vue</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/angularLogo.svg" alt="">
              <div class="text-lg text-center">Angular</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/nodeLogo.svg" alt="">
              <div class="text-lg text-center">Node</div>
            </div>
          </div>
          <div class="technology">
            <div>
              <img class="mb-6 block h-10 mx-auto" src="@/assets/mongoLogo.svg" alt="">
              <div class="text-lg text-center">MongoDB</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="contact" ref="contact" class="relative p-5 pt-16 pb-20 bg-gray-700" :style="{ opacity: contactOpacity }">
      <div class="section">
        <h1 class="text-3xl md:text-5xl font-bold mb-5">
          EjsmontScript - {{$t('contact.menuButton')}}
        </h1>
        <div class="text-2xl mb-6">
          {{$t('contact.text')}}
        </div>
        <div v-if="$store.state.lang === 'pl'">Email: <a href="mailto: biuro@ejsmontscript.com">biuro@ejsmontscript.com</a></div>
        <div v-else-if="$store.state.lang === 'en'">Email: <a href="mailto: office@ejsmontscript.com">office@ejsmontscript.com</a></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { opacityOfSection } from '@/shared/utils/opacityOfSection'
import { isActiveLink } from '@/shared/utils/isActiveLink'
import { locales, changeLocale } from '@/i18n'
import { Locale } from '@/shared/interfaces/locale.model'

export default defineComponent({
  name: 'HomeView',
  data: function () {
    return {
      scrollY: 0 as number,
      activeLink: '#aboutUs' as string,
      langs: locales,
      showLocales: false as boolean
    }
  },
  computed: {
    langNativeName () {
      return locales.find((locale: Locale) => locale.code === this.$store.state.lang)?.nativeName
    },
    aboutUsOpacity: function () {
      const scrollY = this.scrollY
      if (this.$refs && this.$refs.aboutUs) {
        return opacityOfSection(this.$refs.aboutUs as Element)
      } else return 1
    },
    ourProductsOpacity: function () {
      const scrollY = this.scrollY
      if (this.$refs && this.$refs.ourProducts) {
        return opacityOfSection(this.$refs.ourProducts as Element)
      } else return 1
    },
    ourTechnologiesOpacity: function () {
      const scrollY = this.scrollY
      if (this.$refs && this.$refs.ourTechnologies) {
        return opacityOfSection(this.$refs.ourTechnologies as Element)
      } else return 1
    },
    contactOpacity: function () {
      const scrollY = this.scrollY
      if (this.$refs && this.$refs.contact) {
        return opacityOfSection(this.$refs.contact as Element)
      } else return 1
    },
    isLookingLeft: function () {
      const eyeXPosition = this.$store.state.eyeXPosition
      return eyeXPosition < window.visualViewport.width * 0.5
    },
    isLookingRight: function () {
      const eyeXPosition = this.$store.state.eyeXPosition
      return eyeXPosition > window.visualViewport.width * 0.5
    },
    isLookingMiddleX: function () {
      const eyeXPosition = this.$store.state.eyeXPosition
      return eyeXPosition < window.visualViewport.width * 0.7 && eyeXPosition > window.visualViewport.width * 0.3
    },
    isLookingTop: function () {
      const eyeYPosition = this.$store.state.eyeYPosition
      return eyeYPosition < window.visualViewport.height * 0.5
    },
    lightPosition: function () {
      let eyeXPosition = this.$store.state.eyeXPosition
      if (eyeXPosition < 0) eyeXPosition = 0
      if (eyeXPosition > window.visualViewport.width) eyeXPosition = window.visualViewport.width
      return `linear-gradient(to right, black -300%, transparent ${100 * eyeXPosition / window.visualViewport.width}%, black 400%)`
    }
  },
  methods: {
    changeLocale: function (locale: string) {
      changeLocale(locale)
    },
    updateScroll: function () {
      this.scrollY = window.scrollY
      if (this.$refs) {
        if (
          this.$refs.aboutUs && isActiveLink(this.$refs.aboutUs as Element)
        ) this.activeLink = '#aboutUs'
        else if (
          this.$refs.ourProducts && isActiveLink(this.$refs.ourProducts as Element)
        ) this.activeLink = '#ourProducts'
        else if (
          this.$refs.ourTechnologies && isActiveLink(this.$refs.ourTechnologies as Element)
        ) this.activeLink = '#ourTechnologies'
        else if (
          this.$refs.contact && isActiveLink(this.$refs.contact as Element)
        ) this.activeLink = '#contact'
      }
    },
    goTo: function (url: string) {
      window.open(url, '_blank')
    }
  },
  created () {
    this.updateScroll()
    window.addEventListener('scroll', this.updateScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateScroll)
  }
})
</script>

<style lang="scss">
  #webgazerGazeDot, #webgazerVideoContainer {
    @apply hidden #{!important};
  }

  #aboutUs {
    height: calc( 100vh - 2.5rem );
    .homeHero {
      background-image: url('~@/assets/home-hero.jpg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .wrapper {
    width: 50%;
    min-width: 300px;
    max-width: 100%;
    @apply ml-0 md:ml-20;
  }

  .notSeeMeee {
    @media only screen and (min-width: 860px) {
      filter: brightness(0.85);
    }
  }
  .seeMeee {
    filter: brightness(1);
  }
  .section {
    width: 860px;
    @apply max-w-full ml-auto mr-auto;
  }
  .product {
    @apply w-full h-72 max-w-sm mx-auto p-5 flex items-center justify-center flex-wrap rounded-2xl cursor-pointer;
    min-width: 280px;
    box-shadow: 0.125rem 0.125rem 5rem -4rem #9ca3af, -0.125rem -0.125rem 5rem -4rem #9ca3af, 0.125rem 0.125rem 6rem -6rem #9ca3af inset, -0.125rem -0.125rem 6rem -5rem #9ca3af inset
  }
  .technology {
    @apply w-full h-56 max-w-xs mx-auto p-5 flex items-center justify-center flex-wrap rounded-2xl;
    min-width: 280px;
    box-shadow: 0.125rem 0.125rem 5rem -4rem #9ca3af, -0.125rem -0.125rem 5rem -4rem #9ca3af, 0.125rem 0.125rem 6rem -6rem #9ca3af inset, -0.125rem -0.125rem 6rem -5rem #9ca3af inset
  }
</style>
